export default class RsaConfig {

  static getKey() {
 /*    const publicKey = '-----BEGIN RSA PUBLIC KEY-----\n' +
       'MEgCQQDGoBSg57ke90PKeca+O5ItLyTHjV/RFt98Dg8PQYVhmzIveA4CMEA0icOR\n' +
       'unKZfKRS5vUF2NLKaLSr/6oiceZNAgMBAAE=\n' +
       '-----END RSA PUBLIC KEY-----\n';// 512;*/

    /*const publicKey = '-----BEGIN RSA PUBLIC KEY-----\n' +
      '  MIGJAoGBAMO1jBWW5+sYnJtNSfFDZqe9TMC1Y64lcyn2bqeDLJv0WKRavIBzzbT9\n' +
      '  McZSZN0UrhHvZ3Tvtvzeioqiz+bbIAf2LlR5kN84pCMWLTtuTszkqciiM+N8r2vs\n' +
      '  ynLgD+/aRkt90mD9g2kvFge2bE9XfdaNvz6SO6M0KfPrYiRGYS5PAgMBAAE=\n' +
      '-----END RSA PUBLIC KEY-----';// 1024;*/
      const publicKey = '-----BEGIN PUBLIC KEY-----\n' +
           'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCSOhV2kgtk8LvDwUNFekgQWoHu\n' +
           'BrIIpi7qhvlglZTXIPCSzweAgQK/ovu+TaMty1LwvNCNjS37ZsqK/5cMlQNVHnSr\n' +
           'JcwT2mpFmZ1NA9h35d5D4e6ea1g6cImBTj5Tk9T2uAAZ/Ovo4S9EYH/Y5yTqSuQQ\n' +
           'LfB4NqnMaYBUQc0luQIDAQAB\n' +
          '-----END PUBLIC KEY-----';

    /*const publicKey = '-----BEGIN RSA PUBLIC KEY-----\n' +
      'MIIBCgKCAQEArRQxFXZut5aTMGYq2i/o69se8TIgWp3+kf8dGFVIneMvRh4Orncp\n' +
      'C9/ZmXlHV0xm6wHseGYPCr0msJY/LLOYHRWZhxqrG26IYo3ovuOcdlgYd6rBnRwQ\n' +
      'urMawGBUJk/cerxumL4NbHxSROWCH7jGwWfOV/dS1JhIZG4/YjJ8o8QS/D7ISuuK\n' +
      'X2+69iDWiiAErFXbk6RTj2kStavh9/oNb/y6H+uc0JixTTBlZtU8KUwpuyn6c4vS\n' +
      'Wr8k/S6SWM/s3TxGc52vYZkFHRffvNbXmD58NAypgw/urOFUU4Mh+2EiJFtUknkm\n' +
      'yP3mw0pge3G3kr5eiRYvAF32yuUhWAJsFQIDAQAB\n' +
      '-----END RSA PUBLIC KEY-----';// 2048;*/
      
      /*const publicKey = '-----BEGIN PUBLIC KEY-----\n' +
'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3HgAcGfTOuuaraT4NAFx\n' +
'RIP8a9lm8cP1FsHUGIqP3LZCqVo+ITmFsPoIdzZyGRlUr78PERHeVAmLlXKFMqod\n' +
'vrDSkKL9KiwlR3vnRtIKrYVgtx+f8algmL5WMmuOInDv11YULIO4bPFbFlwKuSr0\n' +
'M07gJlLvW3BWKfvOQeabDzE6o0qKgUt1CoTPiC+04y43GxQ/l1uqN6TNUUTwGBTf\n' +
'kEkt5dYNbLe/Y1wi7CVZKDs50HNoFqKGx9D65NpgWls50vinpeP368YDft4fZ6qc\n' +
'paXkYi1GN3mDwm26kGStjK1fG1VHI0BqA5gz609GR0oLep2xqnotGozHndVhxThs\n' +
'SQIDAQAB\n' +
'-----END PUBLIC KEY-----';*/

    return publicKey;
  }

  static getDataLength() {
   // const keyLength = 512;// 79168;

     const keyLength = 1024;// 71808;

    // const keyLength = 2048;// 68608;

    return (keyLength / 8) - 11;
  }
}
